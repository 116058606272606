// @ts-ignore
import Hashes from 'jshashes';

const MD5 = new Hashes.MD5();
const SHA1 = new Hashes.SHA1();
const SHA256 = new Hashes.SHA256();
const SHA512 = new Hashes.SHA512();
const RMD160 = new Hashes.RMD160();

const hash = {
  md5: (content: string): string => MD5.hex(content),
  sha1: (content: string): string => SHA1.hex(content),
  sha256: (content: string): string => SHA256.hex(content),
  sha512: (content: string): string => SHA512.hex(content),
  rmd160: (content: string): string => RMD160.hex(content),
};

export default hash;
