import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Col, Divider, Layout, Row } from 'antd';
import { Routes as CustomRoutes } from '../..';
import Logo from '../Logo';
import NavBar from '../NavBar';
import { AppProvider, ConverterProvider } from '../../contexts';
import './App.css';

const { Header, Content } = Layout;

const App = () => (
  <AppProvider>
    <Layout className="layout">
      <Header className="header">
        <Row>
          <Col flex="200px">
            <Logo />
          </Col>
          <Col flex="20px">
            <Divider type="vertical" />
          </Col>
          <Col flex="auto">
            <NavBar />
          </Col>
        </Row>
      </Header>
      <Content className="content">
        <ConverterProvider>
          <Routes>
            {Object.entries(CustomRoutes).map(([, { path, element }]) => (
              <Route path={path} element={element} key={path} />
            ))}
          </Routes>
        </ConverterProvider>
      </Content>
    </Layout>
  </AppProvider>
);

export default App;
