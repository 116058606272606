import React, { DependencyList, EffectCallback } from 'react';

const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const mounted = React.useRef<boolean>();

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      effect();
    }
  }, deps);
};

export default useUpdateEffect;
