import React from 'react';
import { Checkbox } from 'antd';
import { JsonConverter, OptionText } from '../../components';
import { notification, csv, isError } from '../../../helpers';

const JsonCsvConverter = () => {
  const [hasHeader, setHasHeader] = React.useState<boolean>(true);
  const [skipEmptyLines, setSkipEmptyLines] = React.useState<boolean>(true);
  const [trimHeader, setTrimHeader] = React.useState<boolean>(false);
  const [trimValues, setTrimValues] = React.useState<boolean>(false);
  const [useHeader, setUseHeader] = React.useState<boolean>(true);
  const [quoteAll, setQuoteAll] = React.useState<boolean>(false);

  return (
    <JsonConverter
      leftProps={{
        settingsOptions: [
          {
            clickable: 'true',
            key: 'useHeader',
            label: (
              <Checkbox checked={useHeader} onChange={(e) => setUseHeader(e.target.checked)}>
                <OptionText title="Use headers" subtitle="First line must be column names" />
              </Checkbox>
            ),
          },
          {
            clickable: 'true',
            key: 'quoteAll',
            label: (
              <Checkbox checked={quoteAll} onChange={(e) => setQuoteAll(e.target.checked)}>
                <OptionText title="Quote values" subtitle="Always enclose values in quotes" />
              </Checkbox>
            ),
          },
        ],
        onClick: (editorText, setOtherSideEditorText) => {
          const result = csv.unparse(editorText, { useHeader, quoteAll });
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
      rightProps={{
        mode: 'CSV',
        settingsOptions: [
          {
            clickable: 'true',
            key: 'hasHeader',
            label: (
              <Checkbox checked={hasHeader} onChange={(e) => setHasHeader(e.target.checked)}>
                <OptionText title="Has headers" subtitle="First row is treated as column names" />
              </Checkbox>
            ),
          },
          {
            clickable: 'true',
            key: 'skipEmptyLines',
            label: (
              <Checkbox
                checked={skipEmptyLines}
                onChange={(e) => setSkipEmptyLines(e.target.checked)}
              >
                <OptionText title="Skip empty lines" subtitle="Ignore empty lines" />
              </Checkbox>
            ),
          },
          {
            clickable: 'true',
            key: 'trimHeader',
            label: (
              <Checkbox checked={trimHeader} onChange={(e) => setTrimHeader(e.target.checked)}>
                <OptionText
                  title="Trim header"
                  subtitle="Remove whitespace from both ends of the header"
                />
              </Checkbox>
            ),
          },
          {
            clickable: 'true',
            key: 'trimValues',
            label: (
              <Checkbox checked={trimValues} onChange={(e) => setTrimValues(e.target.checked)}>
                <OptionText
                  title="Trim values"
                  subtitle="Remove whitespace from both ends of each value"
                />
              </Checkbox>
            ),
          },
        ],
        onClick: (editorText, setOtherSideEditorText) => {
          const result = csv.parse(editorText, {
            hasHeader,
            skipEmptyLines,
            trimHeader,
            trimValues,
          });
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
    />
  );
};

export default JsonCsvConverter;
