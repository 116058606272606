import React from 'react';
import { Menu } from 'antd';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Routes from '../../common/Routes';
import { useAppProvider } from '../../contexts';
import { UserMessages } from '../../common';
import './NavBar.css';
import About from '../About';

type NavBarItemType = ItemType & { selectable?: 'true' | 'false' };

const NavBar = () => {
  const { menuSelectedKeys, setMenuSelectedKeys } = useAppProvider();
  const [isAboutVisible, setAboutVisible] = React.useState<boolean>(false);
  const items: Array<NavBarItemType> = React.useMemo(
    () => [
      {
        key: 'all-tools',
        label: UserMessages.allTools,
        icon: <SettingOutlined />,
        children: [
          {
            key: 'encoding',
            type: 'group',
            label: UserMessages.encoding,
            children: [
              {
                key: 'base64',
                label: <Link to={Routes.Base64.path}>{UserMessages.base64}</Link>,
              },
            ],
          },
          {
            key: 'converters',
            type: 'group',
            label: UserMessages.converters,
            children: [
              {
                key: 'json-csv',
                label: <Link to={Routes.JsonCsv.path}>{UserMessages.jsonCsv}</Link>,
              },
              {
                key: 'json-toml',
                label: <Link to={Routes.JsonToml.path}>{UserMessages.jsonToml}</Link>,
              },
              {
                key: 'json-xml',
                label: <Link to={Routes.JsonXml.path}>{UserMessages.jsonXml}</Link>,
              },
              {
                key: 'json-yaml',
                label: <Link to={Routes.JsonYaml.path}>{UserMessages.jsonYaml}</Link>,
              },
            ],
          },
          {
            key: 'generators',
            type: 'group',
            label: UserMessages.generators,
            children: [
              {
                key: 'hash-generator',
                label: <Link to={Routes.HashGenerator.path}>{UserMessages.hashGenerator}</Link>,
              },
            ],
          },
        ],
      },
      {
        key: 'about',
        label: UserMessages.about,
        icon: <InfoCircleOutlined />,
        selectable: 'false',
        style: { marginLeft: 'auto' },
        onClick: () => setAboutVisible(true),
      },
    ],
    [],
  );

  return (
    <>
      <Menu
        mode="horizontal"
        className="navBar"
        items={items}
        selectedKeys={menuSelectedKeys}
        onClick={({ key }) => {
          const navBarMenuItem = items.find((item) => item.key === key);
          if (navBarMenuItem?.selectable !== 'false') {
            setMenuSelectedKeys([key]);
          }
        }}
      />
      <About visible={isAboutVisible} onClose={() => setAboutVisible(false)} />
    </>
  );
};

export default NavBar;
