import React from 'react';
import { Card, Col, Descriptions, Row, Space, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { Link } from 'react-router-dom';
import { AppConfig, Routes, UserMessages } from '../../common';
import './Home.css';

const GUTTER: [Gutter, Gutter] = [
  { xs: 8, sm: 16, md: 24, lg: 32 },
  { xs: 8, sm: 16, md: 24, lg: 32 },
];

const Home = () => (
  <Space direction="vertical" size="large">
    <Card style={{ background: '#F0F7FF' }}>
      <Descriptions title="Tools for everyone!">
        <Descriptions.Item>
          {AppConfig.Name} is a collection of useful web-based tools (including converters,
          encoders, decoders, etc.) to help everyone daily. All tools available here are simple,
          free, and easy to use, right in your browser.
          <br />
          New tools will be added regularly. Stay tuned!
        </Descriptions.Item>
      </Descriptions>
    </Card>
    <Card>
      <Typography.Title level={5} className="homeFeatureTitle">
        Available Tools
      </Typography.Title>
      <Row gutter={GUTTER}>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.Base64.path}>
            <Card hoverable>{UserMessages.base64Text}</Card>
          </Link>
        </Col>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.JsonCsv.path}>
            <Card hoverable>{UserMessages.jsonCsvText}</Card>
          </Link>
        </Col>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.JsonToml.path}>
            <Card hoverable>{UserMessages.jsonTomlText}</Card>
          </Link>
        </Col>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.JsonXml.path}>
            <Card hoverable>{UserMessages.jsonXmlText}</Card>
          </Link>
        </Col>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.JsonYaml.path}>
            <Card hoverable>{UserMessages.jsonYamlText}</Card>
          </Link>
        </Col>
        <Col flex="auto" className="homeFeatureColumn">
          <Link to={Routes.HashGenerator.path}>
            <Card hoverable>{UserMessages.hashGenerator}</Card>
          </Link>
        </Col>
      </Row>
    </Card>
  </Space>
);

export default Home;
