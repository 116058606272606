import React from 'react';
import { Button, Col, Divider, Row, Tag, Tooltip } from 'antd';
import { CopyOutlined, SettingFilled } from '@ant-design/icons';
import clipboard from 'clipboardy';
import { EditorModeKey } from './EditorMode';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu';
import './EditorToolbar.css';
import { notification } from '../../../helpers';
import { UserMessages } from '../../../app';

export interface ToolbarOption {
  tooltipMessage: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export interface EditorToolbarProps {
  mode: EditorModeKey;
  settingsOptions?: Array<DropdownMenuItem>;
  toolbarOptions?: Array<ToolbarOption>;
  copyToClipboard: (copyFunction: (text: string) => void) => void;
}

const EditorToolbar = ({
  mode,
  settingsOptions,
  toolbarOptions,
  copyToClipboard,
}: EditorToolbarProps) => (
  <Row justify="space-between" align="middle" className="editorToolbar">
    <Col flex="auto" className="editorToolbarLeftSide">
      <Tag>{mode}</Tag>
    </Col>
    <Col flex="auto" className="editorToolbarRightSide">
      {toolbarOptions ? (
        <>
          {toolbarOptions.map(({ tooltipMessage, icon, onClick }) => (
            <Tooltip title={tooltipMessage} key={tooltipMessage}>
              <Button className="editorToolbarButton" icon={icon} type="text" onClick={onClick} />
            </Tooltip>
          ))}
          <Divider type="vertical" className="editorDivider" />
        </>
      ) : null}
      <Tooltip title="Copy">
        <Button
          className="editorToolbarButton"
          icon={<CopyOutlined />}
          type="text"
          onClick={() => {
            copyToClipboard((text) =>
              clipboard.write(text).then(() => notification.info(UserMessages.copiedToClipboard)),
            );
          }}
        />
      </Tooltip>
      {settingsOptions ? (
        <DropdownMenu items={settingsOptions}>
          <Button className="editorToolbarButton" icon={<SettingFilled />} type="text" />
        </DropdownMenu>
      ) : null}
    </Col>
  </Row>
);

export default EditorToolbar;
