import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import { Try, TryRun } from '../errorHandling';
import json from './json';
import { Parser, Formatter, Validator } from './types';
import { ErrorMessages } from '../../app';
import { isString } from '../utils';

const xml: Parser & Formatter & Validator = {
  unparse: (content: string): string | Error =>
    Try(() => {
      if (!json.isValid(content)) {
        return new Error(ErrorMessages.invalidJson);
      }

      return new XMLBuilder({ format: true }).build(JSON.parse(content));
    }),
  parse: (content: string): string | Error =>
    Try(() => {
      if (!xml.isValid(content)) {
        return new Error(ErrorMessages.invalidXml);
      }

      return json.prettify(new XMLParser().parse(content), {});
    }),
  minify: (content: string | unknown): string | Error =>
    Try(() => {
      const builder = new XMLBuilder({ format: false });
      if (isString(content)) {
        return builder.build(new XMLParser().parse(content));
      }

      return builder.build(content);
    }),
  prettify: (content: string | unknown): string | Error =>
    Try(() => {
      const builder = new XMLBuilder({ format: true });
      if (isString(content)) {
        return builder.build(new XMLParser().parse(content));
      }

      return builder.build(content);
    }),
  isValid: (content: string): boolean =>
    TryRun(() => new XMLBuilder({}).build(new XMLParser().parse(content))),
};

export default xml;
