export const Try = <T>(supplier: () => T): T | Error => {
  try {
    return supplier();
  } catch (e: unknown) {
    return e as Error;
  }
};

export const TryRun = (block: () => void): boolean => {
  try {
    block();
    return true;
  } catch (e: unknown) {
    return false;
  }
};
