import React from 'react';
import { Col, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import Ad from '../Ad';
import './SplitPane.css';

const GUTTER: Gutter = { xs: 8, sm: 16, md: 24, lg: 32 };

interface SplitPaneProps {
  left: React.ReactNode;
  right: React.ReactNode;
  center?: React.ReactNode;
}

const SplitPane = ({ left, right, center }: SplitPaneProps) => (
  <Row gutter={GUTTER} className="splitPane">
    <Col flex={center ? '40%' : '42%'}>{left}</Col>
    {center ? (
      <Col flex="4%" className="center">
        {center}
      </Col>
    ) : null}
    <Col flex={center ? '40%' : '42%'}>{right}</Col>
    <Col flex="16%">
      <Ad />
    </Col>
  </Row>
);

export default SplitPane;
