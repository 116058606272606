import React from 'react';
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import { JsonConverter } from '../../components';
import { useConverterProvider } from '../../../app';
import { isError, notification, xml } from '../../../helpers';

const JsonXmlConverter = () => {
  const { currentRightEditorText, setCurrentRightEditorText } = useConverterProvider();
  return (
    <JsonConverter
      leftProps={{
        onClick: (editorText, setOtherSideEditorText) => {
          const result = xml.unparse(editorText, {});
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
      rightProps={{
        mode: 'XML',
        toolbarOptions: [
          {
            tooltipMessage: 'Expand',
            icon: <ExpandOutlined />,
            onClick: () => {
              const result = xml.prettify(currentRightEditorText, {});
              if (isError(result)) {
                notification.error(result);
              } else {
                setCurrentRightEditorText(result);
              }
            },
          },
          {
            tooltipMessage: 'Compact',
            icon: <CompressOutlined />,
            onClick: () => {
              const result = xml.minify(currentRightEditorText);
              if (isError(result)) {
                notification.error(result);
              } else {
                setCurrentRightEditorText(result);
              }
            },
          },
        ],
        onClick: (editorText, setOtherSideEditorText) => {
          const result = xml.parse(editorText, {});
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
    />
  );
};

export default JsonXmlConverter;
