import React from 'react';
import Home from '../components/Home';
import {
  Base64Converter,
  JsonXmlConverter,
  JsonCsvConverter,
  JsonYamlConverter,
  JsonTomlConverter,
  HashGenerator,
} from '../../shared/pages';

const Routes = {
  Home: {
    path: '/',
    element: <Home />,
  },
  Base64: {
    path: '/base64',
    element: <Base64Converter />,
  },
  JsonCsv: {
    path: '/json-csv',
    element: <JsonCsvConverter />,
  },
  JsonToml: {
    path: '/json-toml',
    element: <JsonTomlConverter />,
  },
  JsonXml: {
    path: '/json-xml',
    element: <JsonXmlConverter />,
  },
  JsonYaml: {
    path: '/json-yaml',
    element: <JsonYamlConverter />,
  },
  HashGenerator: {
    path: '/hash-generator',
    element: <HashGenerator />,
  },
};

export default Routes;
