import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { Routes } from '../../index';
import { LogoIcon } from '../../../shared/icons';
import { useAppProvider } from '../../contexts';
import './Logo.css';
import { AppConfig } from '../../common';

interface LogoProps {
  className?: string;
}

const Logo = (props: LogoProps) => {
  const { setMenuSelectedKeys } = useAppProvider();
  return (
    <h1 {...props} style={{ minWidth: 'fit-content' }}>
      <Link to={Routes.Home.path} onClick={() => setMenuSelectedKeys([])}>
        <Space>
          <LogoIcon style={{ verticalAlign: 'middle', color: '#107cf1' }} />
          <span className="logoTitle">{AppConfig.Name}</span>
        </Space>
      </Link>
    </h1>
  );
};

export default Logo;
