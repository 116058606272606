import React from 'react';
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import { useConverterProvider } from '../../../app';
import { Converter, ConverterEditorProps } from '../Converter';
import { isError, json, notification } from '../../../helpers';

export interface JsonConverterProps {
  leftProps: Omit<ConverterEditorProps, 'mode' | 'toolbarOptions'>;
  rightProps: ConverterEditorProps;
}

const JsonConverter = ({ leftProps, rightProps }: JsonConverterProps) => {
  const { currentLeftEditorText, setCurrentLeftEditorText } = useConverterProvider();

  return (
    <Converter
      leftProps={{
        mode: 'JSON',
        toolbarOptions: [
          {
            tooltipMessage: 'Expand',
            icon: <ExpandOutlined />,
            onClick: () => {
              const result = json.prettify(currentLeftEditorText, {});
              if (isError(result)) {
                notification.error(result);
              } else {
                setCurrentLeftEditorText(result);
              }
            },
          },
          {
            tooltipMessage: 'Compact',
            icon: <CompressOutlined />,
            onClick: () => {
              const result = json.minify(currentLeftEditorText);
              if (isError(result)) {
                notification.error(result);
              } else {
                setCurrentLeftEditorText(result);
              }
            },
          },
        ],
        ...leftProps,
      }}
      rightProps={rightProps}
    />
  );
};

export default JsonConverter;
