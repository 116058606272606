import React from 'react';

interface AppContextProps {
  menuSelectedKeys: Array<string>;
  setMenuSelectedKeys: (keys: Array<string>) => void;
}

const AppContext = React.createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [menuSelectedKeys, setMenuSelectedKeys] = React.useState<Array<string>>([]);
  const value = React.useMemo(
    () => ({ menuSelectedKeys, setMenuSelectedKeys }),
    [menuSelectedKeys],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppProvider = (): AppContextProps => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('AppContext not available');
  }

  return context;
};
