import React from 'react';
import { Button, Space } from 'antd';
import { SplitPane } from '../SplitPane';
import { Editor, EditorProps } from '../Editor';
import { store, StoreKey } from '../../../helpers';
import { useConverterProvider } from '../../../app';
import { useUpdateEffect } from '../../hooks';
import './Converter.css';

export type ConverterEditorProps = Omit<EditorProps, 'copyToClipboard'> & {
  onClick: (editorText: string, setOtherSideEditorText: (newText: string) => void) => void;
};

export interface ConverterProps {
  leftProps: ConverterEditorProps;
  rightProps: ConverterEditorProps;
}

const Converter = ({
  leftProps: { onClick: leftClick, ...leftProps },
  rightProps: { onClick: rightClick, ...rightProps },
}: ConverterProps) => {
  const {
    currentLeftEditorText,
    currentRightEditorText,
    setCurrentLeftEditorText,
    setCurrentRightEditorText,
    resetCurrentEditors,
  } = useConverterProvider();

  React.useEffect(() => {
    setCurrentLeftEditorText(store.scopedGet(StoreKey.LeftEditorText) ?? '');
    setCurrentRightEditorText(store.scopedGet(StoreKey.RightEditorText) ?? '');
  }, []);

  useUpdateEffect(() => {
    store.scopedSet(StoreKey.LeftEditorText, currentLeftEditorText);
  }, [currentLeftEditorText]);

  useUpdateEffect(() => {
    store.scopedSet(StoreKey.RightEditorText, currentRightEditorText);
  }, [currentRightEditorText]);

  React.useEffect(() => resetCurrentEditors, []);

  return (
    <SplitPane
      left={
        <Editor
          {...leftProps}
          copyToClipboard={(copy) => copy(currentLeftEditorText)}
          onChange={(text: string) => {
            setCurrentLeftEditorText(text);
            store.scopedSet(StoreKey.LeftEditorText, text);
          }}
          value={currentLeftEditorText}
        />
      }
      right={
        <Editor
          {...rightProps}
          copyToClipboard={(copy) => copy(currentRightEditorText)}
          onChange={(text: string) => {
            setCurrentRightEditorText(text);
            store.scopedSet(StoreKey.RightEditorText, text);
          }}
          value={currentRightEditorText}
        />
      }
      center={
        <Space align="center" direction="vertical">
          <Button
            className="converterButton"
            onClick={() => leftClick(currentLeftEditorText, setCurrentRightEditorText)}
          >
            {'>'}
          </Button>
          <Button
            className="converterButton"
            onClick={() => rightClick(currentRightEditorText, setCurrentLeftEditorText)}
          >
            {'<'}
          </Button>
        </Space>
      }
    />
  );
};

export default Converter;
