import { isString } from '../../helpers/utils';
import AppConfig from './AppConfig';

export type ErrorDetail = { title: string; description?: string };

export const createErrorDetail = (error: string | Error): ErrorDetail => {
  if (isString(error)) {
    return { title: error };
  }
  const { reason, message } = error as any;
  if (isString(reason)) {
    return { title: reason, description: message };
  }

  return { title: message };
};

export const ErrorMessages = {
  invalidJson: 'Invalid JSON!',
  invalidToml: 'Invalid TOML!',
  invalidXml: 'Invalid XML!',
  invalidYaml: 'Invalid YAML!',
  invalidBase64: 'Invalid Base64!',
};

export const UserMessages = {
  allTools: 'All tools',
  encoding: 'Encoding',
  generators: 'Generators',
  hashGenerator: 'Hash Generator',
  converters: 'Converters',
  about: 'About',
  aboutText: `${AppConfig.Name} is a collection of web-based tools to help you day to day.`,
  feedback: 'Feedback',
  feedbackText: `If you encounter a bug or have any questions or suggestions, please send an email to ${AppConfig.Email}.`,
  dataPolicy: 'Data Policy',
  dataPolicyText: 'No data is shared anywhere. It always remains on your browser.',
  base64: 'Base64',
  base64Text: 'Base64 Encoding/Decoding',
  jsonCsv: 'JSON <-> CSV',
  jsonCsvText: 'JSON to CSV converter (and vice-versa)',
  jsonXml: 'JSON <-> XML',
  jsonXmlText: 'JSON to XML converter (and vice-versa)',
  jsonYaml: 'JSON <-> YAML',
  jsonYamlText: 'JSON to YAML converter (and vice-versa)',
  jsonToml: 'JSON <-> TOML',
  jsonTomlText: 'JSON to TOML converter (and vice-versa)',
  copiedToClipboard: 'Copied to clipboard!',
};
