import React from 'react';
import { Dropdown, DropdownProps, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export type DropdownMenuItem = ItemType & { clickable: 'true' | 'false' };

export interface DropdownMenuProps
  extends Omit<DropdownProps, 'overlay' | 'onVisibleChange' | 'visible'> {
  children: React.ReactNode;
  items: Array<DropdownMenuItem>;
}

const DropdownMenu = ({ children, items, ...props }: DropdownMenuProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <Dropdown
      overlay={
        <Menu
          onClick={(info) => {
            const dropdownMenuItem = items.find((item) => item.key === info.key);
            setVisible(dropdownMenuItem?.clickable === 'true');
          }}
          items={items}
        />
      }
      onVisibleChange={setVisible}
      visible={visible}
      {...props}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownMenu;
