import React, { CSSProperties } from 'react';
import AceEditor from 'react-ace';
import EditorToolbar, { EditorToolbarProps } from './EditorToolbar';
import { EditorMode, EditorModeKey } from './EditorMode';

import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-toml';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools';

export type EditorProps = EditorToolbarProps & {
  mode: EditorModeKey;
  value?: string;
  showGutter?: boolean;
  onChange?: (value: string) => void;
  style?: CSSProperties;
};

const Editor = ({ mode, value, showGutter = true, onChange, style, ...props }: EditorProps) => (
  <>
    <EditorToolbar mode={mode} {...props} />
    <AceEditor
      mode={EditorMode[mode]}
      theme="xcode"
      showGutter={showGutter}
      setOptions={{ useWorker: false, wrap: true }}
      height="100%"
      width="100%"
      onChange={onChange}
      style={style}
      value={value}
    />
  </>
);

export default Editor;
