import React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import clipboard from 'clipboardy';
import { notification } from '../../../helpers';
import { UserMessages } from '../../../app';

export interface CopyToClipboardInputProps {
  label?: string;
  value?: string;
  placeholder?: string;
  tooltipTitle?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CopyToClipboardInput = ({
  label,
  value,
  placeholder,
  tooltipTitle,
  onChange,
}: CopyToClipboardInputProps) => (
  <Input.Group compact>
    <Input
      allowClear={false}
      value={value}
      placeholder={placeholder}
      style={{ width: 'calc(100% - 32px)' }}
      addonBefore={label}
      onChange={onChange}
    />
    <Tooltip title={tooltipTitle}>
      <Button
        icon={<CopyOutlined />}
        onClick={() => {
          clipboard
            .write(value ?? '')
            .then(() => notification.info(UserMessages.copiedToClipboard));
        }}
      />
    </Tooltip>
  </Input.Group>
);

export default CopyToClipboardInput;
