import YAML from 'js-yaml';
import { Try, TryRun } from '../errorHandling';
import json from './json';
import { Parser, Validator } from './types';
import { ErrorMessages } from '../../app';

const yaml: Parser & Validator = {
  unparse: (content: string): string | Error =>
    Try(() => {
      if (!json.isValid(content)) {
        return new Error(ErrorMessages.invalidJson);
      }

      return YAML.dump(JSON.parse(content), { indent: 2 });
    }),
  parse: (content: string): string | Error =>
    Try(() => {
      if (!yaml.isValid(content)) {
        return new Error(ErrorMessages.invalidYaml);
      }

      return json.prettify(YAML.load(content), {});
    }),
  isValid: (content: string): boolean => TryRun(() => YAML.load(content)),
};

export default yaml;
