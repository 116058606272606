export enum EditorMode {
  CSV = 'plain_text',
  BASE64 = 'plain_text',
  TEXT = 'plain_text',
  XML = 'xml',
  JSON = 'json',
  YAML = 'yaml',
  TOML = 'toml',
  SQL = 'sql',
}

export type EditorModeKey = keyof typeof EditorMode;
