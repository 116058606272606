import React from 'react';
import { JsonConverter } from '../../components';
import { isError, notification, toml } from '../../../helpers';

const JsonTomlConverter = () => (
  <JsonConverter
    leftProps={{
      onClick: (editorText, setOtherSideEditorText) => {
        const result = toml.unparse(editorText, {});
        if (isError(result)) {
          notification.error(result);
        } else {
          setOtherSideEditorText(result);
        }
      },
    }}
    rightProps={{
      mode: 'TOML',
      onClick: (editorText, setOtherSideEditorText) => {
        const result = toml.parse(editorText, {});
        if (isError(result)) {
          notification.error(result);
        } else {
          setOtherSideEditorText(result);
        }
      },
    }}
  />
);

export default JsonTomlConverter;
