export enum StoreKey {
  LeftEditorText = 'LeftEditorText',
  RightEditorText = 'RightEditorText',
}

const get = (key: string): string | null => localStorage.getItem(key);
const set = (key: string, value: string): void => localStorage.setItem(key, value);
const getScope = (): string => location.hash.replace(/^#/, '').replace('/', '').replace(/\//g, '-');

const store = {
  get: (key: StoreKey): string | null => get(key),
  set: (key: StoreKey, value: string): void => set(key, value),
  scopedGet: (key: StoreKey): string | null => get(`${getScope()}-${key}`),
  scopedSet: (key: StoreKey, value: string): void => set(`${getScope()}-${key}`, value),
};

export default store;
