import { isString } from '../utils';
import { Try, TryRun } from '../errorHandling';
import { Formatter, PrettifyConfig, Validator } from './types';

export interface JsonPrettifyConfig extends PrettifyConfig {
  indent?: number;
}

const json: Formatter & Validator = {
  minify: (content: string | unknown): string | Error =>
    Try(() => {
      if (isString(content)) {
        return JSON.stringify(JSON.parse(content));
      }

      return JSON.stringify(content);
    }),
  prettify: (content: string | unknown, { indent = 2 }: JsonPrettifyConfig): string | Error =>
    Try(() => {
      if (isString(content)) {
        return JSON.stringify(JSON.parse(content), null, indent);
      }

      return JSON.stringify(content, null, indent);
    }),
  isValid: (content: string): boolean => TryRun(() => JSON.parse(content)),
};

export default json;
