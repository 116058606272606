import React from 'react';

export const isString = (value: unknown): value is string =>
  typeof value === 'string' || value instanceof String;

export const isError = (value: unknown): value is Error => value instanceof Error;

export const reactStringReplace = (
  text: string,
  search: string,
  replace: React.ReactNode,
): React.ReactNode =>
  text
    .split(new RegExp(`(${search})`, 'g'))
    .map((textPart) => (textPart === search ? replace : textPart));
