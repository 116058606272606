import { Button, Descriptions, Modal, Space } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { AppConfig, UserMessages } from '../../common';
import { reactStringReplace } from '../../../helpers';
import './About.css';

export interface AboutProps {
  visible: boolean;
  onClose: () => void;
}

const About = ({ visible = true, onClose }: AboutProps) => (
  <Modal
    title={
      <Space direction="horizontal">
        <InfoCircleFilled className="aboutIcon" />
        <span className="aboutTitle">{UserMessages.about}</span>
      </Space>
    }
    visible={visible}
    centered
    destroyOnClose
    onCancel={onClose}
    footer={[
      <Button key="close" type="primary" onClick={onClose}>
        Close
      </Button>,
    ]}
  >
    <span className="aboutText">{UserMessages.aboutText}</span>
    <Descriptions title={UserMessages.feedback} className="aboutSectionTitle">
      <span className="aboutSectionContent">
        {reactStringReplace(
          UserMessages.feedbackText,
          AppConfig.Email,
          <a key="email" href={`mailto:${AppConfig.Email}`}>
            {AppConfig.Email}
          </a>,
        )}
      </span>
    </Descriptions>
    <Descriptions title={UserMessages.dataPolicy} className="aboutSectionTitle">
      <span className="aboutSectionContent">{UserMessages.dataPolicyText}</span>
    </Descriptions>
  </Modal>
);

export default About;
