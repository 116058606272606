/* eslint-disable react/jsx-one-expression-per-line */

// Ad Unit: responsive-vertical-ad-unit
const Ad = () => (
  <>
    <script
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1400363074358864"
      crossOrigin="anonymous"
    />
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-1400363074358864"
      data-ad-slot="1223532237"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
    <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
  </>
);

export default Ad;
