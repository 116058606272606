import { notification as antNotification } from 'antd';
import { createErrorDetail } from '../app';

const notification = {
  error: (error: string | Error): void => {
    antNotification.destroy();

    const { title, description } = createErrorDetail(error);
    antNotification.error({ message: title, description });
  },
  info: (message: string): void => {
    antNotification.destroy();
    antNotification.info({ message });
  },
};

export default notification;
