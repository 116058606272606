import { Typography } from 'antd';
import React from 'react';
import './OptionText.css';

interface OptionTextProps {
  title: string;
  subtitle: string;
}

const OptionText = ({ title, subtitle }: OptionTextProps) => (
  <span className="optionText">
    <Typography.Text>{title}</Typography.Text>
    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
      {subtitle}
    </Typography.Text>
  </span>
);

export default OptionText;
