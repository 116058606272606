import React from 'react';
import { Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { CopyToClipboardInput, Editor, SplitPane } from '../../components';
import { useConverterProvider } from '../../../app';
import { hash, store, StoreKey } from '../../../helpers';
import { useDebounce } from '../../hooks';

const GUTTER: [Gutter, Gutter] = [
  { xs: 8, sm: 16, md: 24, lg: 32 },
  { xs: 8, sm: 16, md: 24, lg: 32 },
];

const HashGenerator = () => {
  const { currentLeftEditorText, setCurrentLeftEditorText, resetCurrentEditors } =
    useConverterProvider();

  React.useEffect(() => resetCurrentEditors, []);

  React.useEffect(() => {
    setCurrentLeftEditorText(store.scopedGet(StoreKey.LeftEditorText) ?? '');
  }, []);

  const [md5Value, setMd5Value] = React.useState<string>('');
  const [sha1Value, setSha1Value] = React.useState<string>('');
  const [sha256Value, setSha256Value] = React.useState<string>('');
  const [sha512Value, setSha512Value] = React.useState<string>('');
  const [rmd160Value, setRmd160Value] = React.useState<string>('');

  const editorText = useDebounce(currentLeftEditorText);

  React.useEffect(() => {
    if (editorText === '') {
      setMd5Value('');
      setSha1Value('');
      setSha256Value('');
      setSha512Value('');
      setRmd160Value('');
    } else {
      setMd5Value(hash.md5(editorText));
      setSha1Value(hash.sha1(editorText));
      setSha256Value(hash.sha256(editorText));
      setSha512Value(hash.sha512(editorText));
      setRmd160Value(hash.rmd160(editorText));
    }
  }, [editorText]);

  return (
    <SplitPane
      left={
        <Editor
          mode="TEXT"
          copyToClipboard={(copy) => copy(currentLeftEditorText)}
          onChange={(text: string) => {
            setCurrentLeftEditorText(text);
            store.scopedSet(StoreKey.LeftEditorText, text);
          }}
          value={currentLeftEditorText}
        />
      }
      right={
        <Row gutter={GUTTER} justify="end">
          <CopyToClipboardInput value={md5Value} label="MD5" />
          <CopyToClipboardInput value={sha1Value} label="SHA1" />
          <CopyToClipboardInput value={sha256Value} label="SHA256" />
          <CopyToClipboardInput value={sha512Value} label="SHA512" />
          <CopyToClipboardInput value={rmd160Value} label="RMD160" />
        </Row>
      }
    />
  );
};

export default HashGenerator;
