import React from 'react';

interface ConverterContextProps {
  currentLeftEditorText: string;
  setCurrentLeftEditorText: (value: string) => void;

  currentRightEditorText: string;
  setCurrentRightEditorText: (value: string) => void;

  resetCurrentEditors: () => void;
}

const ConverterContext = React.createContext<ConverterContextProps | undefined>(undefined);

interface ConverterProviderProps {
  children?: React.ReactNode;
}

export const ConverterProvider: React.FC<ConverterProviderProps> = ({ children }) => {
  const [currentLeftEditorText, setCurrentLeftEditorText] = React.useState<string>('');
  const [currentRightEditorText, setCurrentRightEditorText] = React.useState<string>('');

  const value = React.useMemo(
    () => ({
      currentLeftEditorText,
      setCurrentLeftEditorText,
      currentRightEditorText,
      setCurrentRightEditorText,
      resetCurrentEditors: () => {
        setCurrentLeftEditorText('');
        setCurrentRightEditorText('');
      },
    }),
    [currentLeftEditorText, currentRightEditorText],
  );

  return <ConverterContext.Provider value={value}>{children}</ConverterContext.Provider>;
};

export const useConverterProvider = (): ConverterContextProps => {
  const context = React.useContext(ConverterContext);

  if (!context) {
    throw new Error('ConverterContext not available');
  }

  return context;
};
