import React from 'react';
import { JsonConverter } from '../../components';
import { isError, notification, yaml } from '../../../helpers';

const JsonYamlConverter = () => (
  <JsonConverter
    leftProps={{
      onClick: (editorText, setOtherSideEditorText) => {
        const result = yaml.unparse(editorText, {});
        if (isError(result)) {
          notification.error(result);
        } else {
          setOtherSideEditorText(result);
        }
      },
    }}
    rightProps={{
      mode: 'YAML',
      onClick: (editorText, setOtherSideEditorText) => {
        const result = yaml.parse(editorText, {});
        if (isError(result)) {
          notification.error(result);
        } else {
          setOtherSideEditorText(result);
        }
      },
    }}
  />
);

export default JsonYamlConverter;
