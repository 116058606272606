import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const IconComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 1080.000000 1080.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
    height="2em"
    width="2em"
  >
    <g
      transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M3970 8073 l-525 -296 -485 -538 -485 -538 -7 -78 -7 -78 222 -223
222 -222 79 7 80 6 298 269 c165 148 302 268 307 266 19 -8 428 -460 559 -618
168 -202 321 -376 509 -577 73 -77 133 -147 133 -155 0 -54 -67 -113 -951
-839 -492 -404 -931 -771 -976 -816 -147 -147 -277 -337 -403 -593 -72 -147
-73 -158 -9 -289 71 -145 201 -262 353 -317 80 -29 108 -23 257 51 250 126
415 240 569 395 47 47 420 487 829 978 687 824 748 894 797 918 29 14 61 24
72 22 51 -8 715 -654 1197 -1163 225 -239 754 -800 946 -1005 167 -178 199
-198 265 -167 35 17 421 402 456 456 21 30 23 106 5 137 -7 12 -84 88 -172
170 -370 342 -1360 1291 -1571 1505 -279 285 -698 743 -692 757 1 5 374 383
828 839 810 815 829 833 1035 994 207 163 211 167 417 403 114 131 208 242
208 245 0 3 -65 71 -144 150 l-143 143 -240 -206 c-239 -206 -240 -207 -426
-439 -178 -221 -224 -270 -991 -1042 l-804 -810 -158 155 c-210 206 -459 428
-709 635 -132 110 -558 503 -563 520 -1 5 130 246 292 535 l295 525 -112 113
c-61 61 -116 112 -122 112 -5 0 -246 -134 -535 -297z m562 100 c26 -26 48 -50
48 -54 0 -3 -120 -220 -267 -482 l-268 -476 -517 -465 c-423 -382 -522 -466
-545 -466 -23 0 -60 32 -210 182 -141 141 -183 189 -183 208 0 19 117 154 473
548 l472 523 470 264 c259 145 472 264 475 264 2 1 26 -20 52 -46z m3628 -203
c0 -3 -75 -92 -167 -198 -159 -181 -180 -200 -393 -370 -224 -178 -228 -181
-1164 -1125 l-940 -947 -43 42 c-24 23 -43 46 -43 51 0 5 421 431 935 948 908
912 940 946 1119 1170 177 221 193 237 377 395 l192 166 63 -63 c35 -35 64
-66 64 -69z m-3900 -1161 c113 -104 207 -195 209 -201 3 -10 -287 -308 -300
-308 -5 0 -180 186 -367 392 l-43 46 143 131 c79 71 145 130 148 131 3 0 97
-86 210 -191z m451 -400 c213 -176 451 -392 623 -562 l160 -158 -88 -88 -89
-89 -117 -3 c-113 -4 -120 -5 -191 -42 l-73 -37 -54 52 c-151 148 -622 688
-622 714 0 7 310 324 316 324 1 0 62 -50 135 -111z m1225 -1214 c98 -110 234
-257 301 -326 l123 -127 0 -328 0 -327 -228 224 c-203 200 -370 358 -546 517
l-59 53 29 57 c27 54 29 64 29 184 l0 128 75 75 c41 42 80 74 86 73 6 -2 92
-93 190 -203z m-578 102 c96 -96 112 -125 112 -202 0 -57 -16 -108 -43 -133
-12 -12 -51 -30 -86 -41 -35 -11 -85 -34 -110 -52 -31 -22 -284 -318 -761
-892 -743 -893 -859 -1025 -994 -1133 -98 -79 -275 -186 -411 -249 -92 -43
-103 -46 -136 -36 -110 33 -224 134 -282 250 -20 40 -37 82 -37 93 0 23 139
287 203 385 62 96 152 205 244 295 43 42 470 397 948 789 479 391 882 726 898
743 15 17 48 72 73 122 24 51 53 99 63 108 29 27 93 45 161 45 l65 1 93 -93z
m1172 -728 l30 -32 0 -323 c0 -185 -4 -324 -9 -324 -5 0 -23 16 -40 35 l-31
36 0 313 c0 275 3 326 17 326 1 0 16 -14 33 -31z m720 -695 c289 -274 736
-697 875 -827 l50 -46 -202 -202 -202 -203 -38 40 c-21 21 -135 142 -253 269
-118 126 -347 368 -507 537 l-293 308 0 314 c0 173 3 322 6 331 5 13 32 -8
123 -97 64 -63 262 -254 441 -424z"
      />
      <path
        d="M4030 4273 c-31 -21 -167 -140 -237 -207 -57 -55 -64 -75 -33 -106
38 -38 62 -26 192 86 154 133 168 149 168 183 0 48 -50 72 -90 44z"
      />
      <path
        d="M3438 3773 c-299 -240 -376 -316 -512 -507 -34 -47 -88 -134 -120
  -192 -51 -91 -58 -110 -50 -132 11 -32 57 -51 82 -36 20 13 43 47 100 151 54
101 181 269 268 358 38 39 151 136 251 217 100 82 184 157 188 168 9 30 -2 55
-31 68 -14 7 -29 12 -34 12 -6 0 -69 -48 -142 -107z"
      />
    </g>
  </svg>
);

export type LogoIconProps = Omit<IconComponentProps, 'ref'>;

const LogoIcon = (props: LogoIconProps) => <Icon component={IconComponent} {...props} />;

export default LogoIcon;
