import { Base64 } from 'js-base64';
import { Try } from '../errorHandling';

export interface Base64EncodingConfig {
  urlSafe?: boolean;
}

const base64 = {
  encode: (content: string, { urlSafe = false }: Base64EncodingConfig): string | Error =>
    Try(() => Base64.encode(content, urlSafe)),
  decode: (content: string): string | Error => Try(() => Base64.decode(content)),
};

export default base64;
