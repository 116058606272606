import Papa from 'papaparse';
import json from './json';
import { Try } from '../errorHandling';
import { Parser, UnparseConfig, ParseConfig } from './types';
import { ErrorMessages } from '../../app';

interface JsonToCsvConfig extends UnparseConfig {
  useHeader?: boolean;
  quoteAll?: boolean;
}

interface CsvToJsonConfig extends ParseConfig {
  hasHeader?: boolean;
  skipEmptyLines?: boolean;
  trimHeader?: boolean;
  trimValues?: boolean;
}

const csv: Parser = {
  unparse: (
    content: string,
    { useHeader = true, quoteAll = false }: JsonToCsvConfig,
  ): string | Error =>
    Try(() => {
      if (!json.isValid(content)) {
        return new Error(ErrorMessages.invalidJson);
      }

      return Papa.unparse(JSON.parse(content), {
        header: useHeader,
        quotes: quoteAll,
      });
    }),
  parse: (
    content: string,
    {
      hasHeader = false,
      skipEmptyLines = false,
      trimHeader = false,
      trimValues = false,
    }: CsvToJsonConfig,
  ): string | Error =>
    Try(() => {
      const trim = (text: string): string => text.trim();
      const { data } = Papa.parse(content, {
        header: hasHeader,
        skipEmptyLines,
        transformHeader: trimHeader ? trim : undefined,
        transform: trimValues ? trim : undefined,
      });

      return json.prettify(data, {});
    }),
};

export default csv;
