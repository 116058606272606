import * as TOML from 'toml-patch';
import { Try, TryRun } from '../errorHandling';
import json from './json';
import { Parser, Validator } from './types';
import { ErrorMessages } from '../../app';

const toml: Parser & Validator = {
  unparse: (content: string): string | Error =>
    Try(() => {
      if (!json.isValid(content)) {
        return new Error(ErrorMessages.invalidJson);
      }

      return TOML.stringify(JSON.parse(content));
    }),
  parse: (content: string): string | Error =>
    Try(() => {
      if (!toml.isValid(content)) {
        return new Error(ErrorMessages.invalidToml);
      }

      return json.prettify(TOML.parse(content), {});
    }),
  isValid: (content: string): boolean => TryRun(() => TOML.parse(content)),
};

export default toml;
