import React from 'react';
import { Checkbox } from 'antd';
import { Converter, OptionText } from '../../components';
import { base64, isError, notification } from '../../../helpers';

const Base64Converter = () => {
  const [isUrlSafe, setUrlSafe] = React.useState<boolean>(false);
  return (
    <Converter
      leftProps={{
        mode: 'TEXT',
        settingsOptions: [
          {
            clickable: 'true',
            key: 'urlSafe',
            label: (
              <Checkbox checked={isUrlSafe} onChange={(e) => setUrlSafe(e.target.checked)}>
                <OptionText
                  title="URL-safe encoding"
                  subtitle="Encode using RFC 4648 to allow usage in URLs"
                />
              </Checkbox>
            ),
          },
        ],
        onClick: (editorText, setOtherSideEditorText) => {
          const result = base64.encode(editorText, { urlSafe: isUrlSafe });
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
      rightProps={{
        mode: 'BASE64',
        onClick: (editorText, setOtherSideEditorText) => {
          const result = base64.decode(editorText);
          if (isError(result)) {
            notification.error(result);
          } else {
            setOtherSideEditorText(result);
          }
        },
      }}
    />
  );
};

export default Base64Converter;
